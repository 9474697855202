import { configureStore } from "@reduxjs/toolkit";
import sidebarToggleSlice from "./features/sidebarToggleSlice";
import userInfoSlice from "./features/userInfoSlice";
import selectedCustomerListSlice from "./features/selectedCustomerListSlice";
import customersDataSlice from "./features/customersDataSlice";
const store=configureStore({
    reducer:{
        sidebarToggle:sidebarToggleSlice,
        selectedCustomerList:selectedCustomerListSlice,
        userInfo:userInfoSlice,
        customerList:customersDataSlice,
    },
})

export default store;