import React, { useState,useEffect } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import SimpleBar from "simplebar-react";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar } from "../features/sidebarToggleSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { Routes, useNavigate } from "react-router-dom";
import { customerStore } from "../features/customersDataSlice";

const CustomerType = () => {
  const [inputFields, setInputFields] = useState([""]);
  
  const isSidebarToggled = useSelector((state) => state.sidebarToggle.isSidebarToggled);
  const token = useSelector((state) => state.userInfo.token);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const customerList = useSelector((state) => state.customerList.customerList);

  const dispatch = useDispatch();
  const navigate= useNavigate();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const handleAddField = () => {
    setInputFields([...inputFields, ""]);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index] = event.target.value;
    setInputFields(values);
  };

  const handleDeleteField = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      customerTypeSave({
        company_id: userInfo.data.company.iCompanyID, 
        category: inputFields,
        user_token:token
      });

    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to submit the Categories. Please try again.");
    }
  };

  const CustomerListData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await axios.post(
        // "/api/v1/customerviewset/list_users/",
        "https://suvarnakarsoftware.com/api/v1/get-customers-list",
        {
          // page:1,
          // limit:10,
          company_id:userInfo.data.company.iCompanyID,
          user_token:token
        },
        config
      );
      console.log(res.data.status)
      if (res.data&&res.data.status) {
        dispatch(customerStore(res?.data))
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    } 
  };

  const customerCategoryData= async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await axios.post(
        "https://suvarnakarsoftware.com/api/v1/get-category-list",
        // "http://127.0.0.1:8000/api/v1/categoriesviewset/list_category/1/",
        {company_id:userInfo.data.company.iCompanyID,
        user_token:token},
        config
      );

      if (res.data && res.data.status) {
        const newCategories = JSON.parse(res.data.data[0].category);
        const previousCategories = res.data.data[1]
          ? JSON.parse(res.data.data[1].category)
          : [];
          setInputFields(newCategories);
          return { newCategories, previousCategories };

      } else {
        setInputFields([]);
        return { newCategories: [], previousCategories: [] };

      } 

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
      return { newCategories: [], previousCategories: [] };
    }
  };


  const customerTypeSave = async (categories_data) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        }
      };

      const res = await axios.post(
        // "/api/v1/categoriesviewset/save_category/",
        "https://suvarnakarsoftware.com/api/v1/create-category",
        categories_data,
        config
      );

      if(res.data&&res.data.status){
        toast.success(res.data.message)
        await compareAndUpdateCustomerCategories()
      } else{
        toast.error(res.data.message)
      }
      
    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
    // finally{
    //   setInputFields([""]);
    // }
  };

  const compareAndUpdateCustomerCategories = async () => {

    const { newCategories, previousCategories } = await customerCategoryData()
    

    previousCategories.forEach(async(prevCategory, index) => {
      if (index < newCategories.length) {

        if (prevCategory !== newCategories[index]) {
          console.log(prevCategory,newCategories[index])
          const affectedUsers = customerList.data.filter((customer) => customer.customer_type === prevCategory);
          const customerGroups = affectedUsers.map((customer) => customer.iUserID);
          console.log(affectedUsers)

          await updateCustomerCategory({
            customer_id: customerGroups,
            customer_type:  newCategories[index],
            company_id: userInfo.data.company.iCompanyID,
            user_token: token
          });
        }
      }
      //else {

      //   const extraUsers = userList.filter((user) => user.category === prevCategory);
      //   extraUsers.forEach((user) => (user.category = null));
      // }
    });

    await CustomerListData()

    
  }

  const updateCustomerCategory = async (userCategory)=>{
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await axios.post(
        // "http://127.0.0.1:8000/api/v1/categoriesviewset/update_user_category/",
        "https://suvarnakarsoftware.com/api/v1/update-customer-category",
        userCategory,
        config
      );

      // toast.success(res.data.message)

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  }
  useEffect(() => {
    
    if(!token){
      navigate('/error')
    } else{
      const fetchData = async () => {
        try {
          if(!customerList.data){
            await CustomerListData();
          }
          const { newCategories, previousCategories } = await customerCategoryData();

        } catch (error) {
          console.error('Error fetching data:', error);
        } 
      };
      fetchData();

    }

  }, [token,navigate,customerList.data]);

  return (
    <div className={`page-wrapper ${isSidebarToggled ? "toggled" : ""}`}>
      <SideBar />

      <main className="page-content bg-gray-50 dark:bg-slate-800">
        <Header toggleSidebar={handleToggleSidebar} />
        <div className="container-fluid relative px-3">
          <div className="layout-specing">
            <div className="shadow dark:shadow-slate-800 rounded bg-white dark:bg-slate-900">
              <div className="py-5 px-8">
                <h5 className="text-lg font-semibold">Customer Type</h5>
              </div>
              <div className="py-5 px-8 border-t border-gray-100 dark:border-slate-800">
                <form onSubmit={handleSubmit} className="space-y-4">
                {inputFields.map((input, index) => (
                  <div key={index} className="flex items-center space-x-2 mt-2">
                    <div className="flex-1">
                      <label className="form-label font-semibold">
                        Category{index + 1}
                      </label>
                      <div className="flex items-center space-x-3">
                        <input
                          type="text"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                          value={input}
                          onChange={(event) => handleInputChange(index, event)}
                          placeholder={`Categorie ${index + 1}`}
                          required
                        />
                        <span
                          className="mdi mdi-trash-can cursor-pointer text-red-600 flex items-center justify-center h-10 bg-gray-100 dark:bg-slate-800 border border-gray-300 dark:border-gray-700 rounded p-2 ml-2 mt-2"
                          style={{ fontSize: '24px' }}
                          onClick={() => handleDeleteField(index)}
                        ></span>
                      </div>
                    </div>
                  </div>
                ))}
                  <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={handleAddField}
                    className="py-2 px-5 mx-3 my-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  text-white rounded-md bg-indigo-600  hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700"
                  >
                    Add Another
                  </button>
                  <button
                    type="submit"
                    className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  text-white rounded-md bg-indigo-600  hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700"
                  >
                    Save
                  </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default CustomerType;
