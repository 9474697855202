import React, { useState, useEffect, useRef } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./templates.css";
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { removeCustomerList } from "../features/selectedCustomerListSlice";

const NewOrderTemplates = () => {
    const [templatesData, setTemplatesData] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [defaultTemplate, setDefaultTemplate] = useState();
    const [changeDefaultTemplate, setChangeDefaultTemplate] = useState();
    const isSidebarToggled = useSelector((state) => state.sidebarToggle.isSidebarToggled);
    const selectedRows = useSelector((state) => state.selectedCustomerList.customerList);
    const dispatch = useDispatch();
  
    const handleToggleSidebar = () => {
      dispatch(toggleSidebar());
    };
    const templatesDataFetch = async () => {
        try {
          const config = {
            headers: {
              "Content-type": "application/json",
            },
          };
          const res = await axios.get(
            "/api/v1/templateviewset/list_templates?category_name=new_order&company_id=1",
            config
          );
          if (res.data.template_list) {
            setTemplatesData(res?.data.template_list);
            setDefaultTemplate(res?.data.default_template);

          }
        } catch (error) {
          if (error.response) {
            console.error("Response Error:", error.response.data);
            console.error("Status Code:", error.response.status);
          } else if (error.request) {
            console.error("Request Error:", error.request);
          } else {
            console.error("Error:", error.message);
          }
        }
      };
    const handleSetDefaultTemplate = async (template) => {
        try {
          const config = {
            headers: {
              "Content-type": "application/json",
            },
          };
          const res = await axios.post(
            "/api/v1/templateviewset/set_default/",
            {category_name:"new_order",
              company_id:1,
              template_id:template.id,
            },
            config
          );
         if(res.data.success){
          setDefaultTemplate(template.id)
          toast.success(res.data.message)
         }
        } catch (error) {
          if (error.response) {
            console.error("Response Error:", error.response.data);
            console.error("Status Code:", error.response.status);
          } else if (error.request) {
            console.error("Request Error:", error.request);
          } else {
            console.error("Error:", error.message);
          }
        }
      };

      useEffect(() => {
        templatesDataFetch()

      }, [defaultTemplate]);

  return (
    <>
     <div className={`page-wrapper ${isSidebarToggled ? 'toggled' : ''}`}>
        <SideBar />

        <main className="page-content bg-gray-50 dark:bg-slate-800">
          <Header toggleSidebar={handleToggleSidebar}/>
          <div className="container-fluid relative px-3">
            <div className="layout-specing">
              <div className="md:flex justify-between items-center">
                <div>
                  <h5 className="text-lg font-semibold">Templates</h5>
                </div>

              </div>
              <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 mt-6 gap-6">
                {templatesData?templatesData.map((template) => (
                  <div
                    className={`relative flex flex-col justify-between rounded-md shadow dark:shadow-gray-700 overflow-hidden min-h-[300px]  ${
                      selectedTemplate === template.id
                        ? " border-4  !important"
                        : "bg-white dark:bg-slate-900"
                    }`}
                  >

                    <div className="content p-6 flex-grow">
                      <h6 className="mb-4 text-15 font-bold">
                        {template.template_name}
                      </h6>
                  <SimpleBar style={{ maxHeight: "200px"}} autoHide={false}>

                      <div className="mb-2  me-3 whitespace-pre-line  break-words">{template.header_content}</div>
                      <div className="mb-2  me-3 whitespace-pre-line  break-words">{template.content}</div>
                    
                      <div className="variables mb-4">
                      </div>
                    </SimpleBar>  
                    </div>
                    <div className="px-6 pb-6 relative bottom-0 left-0">
                        

                        <button
                          onClick={() => {
                            handleSetDefaultTemplate(template);
                          }}
                          className={`py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center  text-white rounded-md  ${
                            defaultTemplate === template.id
                              ? " bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700"
                              : "bg-indigo-600  hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700"
                          }`}
                        >
                        { defaultTemplate === template.id?"Selected Default": "Default Template"}
                         
                        </button>
                        
                     
                    </div>
                  </div>
                )):null}
              </div>
            </div>
          </div>

          <Footer />
        </main>
      </div>
    </>
  )
}

export default NewOrderTemplates
