import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
function Loader() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div role="status">
        <BeatLoader 
        color="rgb(79, 70, 229)"
        // loading={loading}
        // cssOverride={override}
        size={25}
        aria-label="Loading Spinner"
        data-testid="loader"
        />
          
        
        {/* <span className="sr-only">Loading...</span> */}
      </div>
      
    </div>
  );
}

export default Loader;
