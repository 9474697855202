import { createSlice } from "@reduxjs/toolkit";

const checkedCustomer=localStorage.getItem('checkedCustomer')?JSON.parse(localStorage.getItem('checkedCustomer')):{}
const selectedCustomer=localStorage.getItem('selectedCustomer')?JSON.parse(localStorage.getItem('selectedCustomer')):{}
// const token=localStorage.getItem('token')?JSON.parse(localStorage.getItem('token')):null

const initialState={
    customerList:selectedCustomer,
    checkedCustomerList:checkedCustomer
}

const selectedCustomerListSlice=createSlice({
    name:"selectedCustomerList",
    initialState,
    reducers: {
        storeCustomerList: (state, action) => {
            state.customerList = action.payload;
            localStorage.setItem('selectedCustomer',JSON.stringify(action.payload))

        },
        storeCheckedCustomerList:(state,action)=>{
            state.checkedCustomerList = action.payload;
            localStorage.setItem('checkedCustomer',JSON.stringify(action.payload))

        },
        removeCustomerList: (state, action) => {
            state.customerList = {};
            localStorage.removeItem('selectedCustomer')

        },
        removeCheckedCustomerList: (state, action) => {
            state.checkedCustomerList = {};
            localStorage.removeItem('checkedCustomer')
        },
    }
});

export const { storeCustomerList,storeCheckedCustomerList,removeCustomerList,removeCheckedCustomerList } = selectedCustomerListSlice.actions;

export default selectedCustomerListSlice.reducer;
