import { createSlice } from "@reduxjs/toolkit";

const initialState={
    customerList:[]
}

const customersDataSlice=createSlice({
    name:"customerList",
    initialState,
    reducers: {
        customerStore: (state, action) => {
            state.customerList = action.payload;
        },
    }
});

export const { customerStore } = customersDataSlice.actions;

export default customersDataSlice.reducer;
