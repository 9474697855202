import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
function Header({ toggleSidebar} ) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const userInfo = useSelector((state) => state.userInfo.userInfo);
    const isSidebarToggled = useSelector((state) => state.sidebarToggle.isSidebarToggled);

    return (
        <>
            <div className="top-header">
                <div className="header-bar flex justify-between">
                    <div className="flex items-center space-x-1">

                        {!isSidebarToggled&&<a href="#" className="xl:hidden block me-2">
                        <div className="logo-container flex items-center justify-around space-x-2">
                            <img src="static/assets/images/logo-crm.png" height="" alt="" className='logo'/>
                            <span className='logo-text font-bold text-lg'>Suvarnakar CRM</span>
                        </div>
                        </a>}



                        <a id="close-sidebar" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[20px] text-center bg-gray-50 dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700 border border-gray-100 dark:border-gray-800 text-slate-900 dark:text-white rounded-full cursor-pointer" 
                        onClick={toggleSidebar}>   
                            <span className="mdi mdi-menu"></span>
                        </a>
                        {/* <div className="ps-1.5">
                            <div className="form-icon relative sm:block hidden">
                                <i className="uil uil-search absolute top-1/2 -translate-y-1/2 start-3"></i>
                                <input type="text" className="form-input w-56 ps-9 py-2 px-3 h-8 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-3xl outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 bg-white" name="s" id="searchItem" placeholder="Search..." />
                            </div>
                        </div> */}

                    </div>
                    <div className="list-none mb-0 space-x-1">
                        <div className="dropdown inline-block relative m-0.5">
                                {/* <button data-dropdown-toggle="dropdown" className=" items-center" type="button" onClick={toggleDropdown}> */}
                                    {/* <span className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[20px] text-center bg-gray-50 dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700 border border-gray-100 dark:border-gray-800 text-slate-900 dark:text-white rounded-full">
                                        <img src="assets/images/client/05.jpg" className="rounded-full" alt=""/>
                                    </span> */}
                                    <span className="font-bold text-[20px] ms-1 sm:inline-block ">{userInfo&&userInfo.data&&userInfo.data.company.vCompanyName}</span>
                                {/* </button> */}
                                </div>
                                </div>
                    {/* <ul className="list-none mb-0 space-x-1">
                        <li className="dropdown inline-block relative m-0.5">
                                <button data-dropdown-toggle="dropdown" className=" items-center" type="button" onClick={toggleDropdown}>
                                    <span className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-[20px] text-center bg-gray-50 dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700 border border-gray-100 dark:border-gray-800 text-slate-900 dark:text-white rounded-full">
                                        <img src="assets/images/client/05.jpg" className="rounded-full" alt=""/>
                                    </span>
                                    <span className="font-semibold text-[16px] ms-1 sm:inline-block hidden">Cristina Murfy</span>
                                </button>
                                
                                <div className={`absolute right-0 m-0 mt-5 z-10 w-44  rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 ${isDropdownOpen ? 'block' : 'hidden'}`} onclick="event.stopPropagation(); ">
                                    <ul className="py-2 text-start">
                                        <li>
                                            <a href="profile.html" className="block font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
                                                <i className="uil uil-user me-2"></i>
                                                Profile
                                            </a>
                                        </li>
                                        <li>
                                            <a href="chat.html" className="block font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
                                                <i className="uil uil-comment me-2"></i>
                                                Chat
                                            </a>
                                        </li>
                                        <li>
                                            <a href="email.html" className="block font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
                                                <i className="uil uil-envelope me-2"></i>
                                                Email
                                            </a>
                                        </li>
                                        <li>
                                            <a href="profile-setting.html" className="block font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
                                                <i className="uil uil-setting me-2"></i>
                                                Settings
                                            </a>
                                        </li>
                                        <li className="border-t border-gray-100 dark:border-gray-800 my-2"></li>
                                        <li>
                                            <a href="lock-screen.html" className="block font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
                                                <i className="uil uil-lock me-2"></i>
                                                Lockscreen
                                            </a>
                                        </li>
                                        <li>
                                            <a href="login.html" className="block font-medium py-1 px-4 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
                                                <i className="uil uil-sign-out-alt me-2"></i>
                                                Logout
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                    </ul> */}
                </div>
            </div>
        </>
    )
}

export default Header
