import { createSlice } from "@reduxjs/toolkit";

const userInfoFromStorage=localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')):null
const token=localStorage.getItem('token')?JSON.parse(localStorage.getItem('token')):null
const initialState={
    userInfo:userInfoFromStorage,
    token:token
};
const userInfoSlice=createSlice({
    name: "userInfo",
    initialState,
    reducers:{
       
        storeUserInfo(state,action){
            state.loading=false;
            state.userInfo=action.payload;
            localStorage.setItem('userInfo',JSON.stringify(action.payload))
        },
        storeToken(state,action){
            state.loading=false;
            state.token=action.payload;
            localStorage.setItem('token',JSON.stringify(action.payload))
        },
    },
});

export default userInfoSlice.reducer;
export const {storeUserInfo,storeToken} =userInfoSlice.actions;