import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "./Loader";
import { storeToken, storeUserInfo } from "../features/userInfoSlice";

const ConnectToWhatsapp = () => {

  const [whatsappAccessToken,setWhatsappAccessToken]=useState()
  const [whatsappApiEndpoint,setWhatsappApiEndpoint]=useState()

  const token = useSelector((state) => state.userInfo.token);
  const userInfo = useSelector((state) => state.userInfo.userInfo);

  const dispatch = useDispatch();
  const navigate=useNavigate()

  const isSidebarToggled = useSelector(
    (state) => state.sidebarToggle.isSidebarToggled
  );

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const ConnectToWhatsappDataSave = async (tokens) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        "https://crm.suvarnakarsoftware.com/api/v1/whatsappviewset/send_whatsapp_token/",
        tokens,
        config
      );

      toast.success(data.message)

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };


  const ConnectToWhatsappDataGet = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.get(
        `https://crm.suvarnakarsoftware.com/api/v1/whatsappviewset/get_whatsapp_token/${userInfo.data.company.iCompanyID}`,
        config
      );
      if(data){
        setWhatsappAccessToken(data.access_token);
        setWhatsappApiEndpoint(data.u_id);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };


  const submitHandler=(e)=>{
    e.preventDefault();

    ConnectToWhatsappDataSave({
        company_id:userInfo.data.company.iCompanyID,
        u_id:whatsappApiEndpoint,
        access_token:whatsappAccessToken,
    })
  }

  useEffect(() => {
    
    if(!token){
      navigate('/error')
      return;
    } else{
      ConnectToWhatsappDataGet()
    }

  }, [token,navigate]);


  return <>
    <div className={`page-wrapper ${isSidebarToggled ? 'toggled' : ''}`}>
      <SideBar />
      <main className="page-content bg-gray-50 dark:bg-slate-800">
        <Header toggleSidebar={handleToggleSidebar} />
        <div className="container-fluid relative px-3">
          <div className="layout-specing">
          <div className="shadow dark:shadow-slate-800 rounded bg-white dark:bg-slate-900">
                <div className="py-5 px-8">
                  <h5 className="text-lg font-semibold">Connect To WhatsApp</h5>
                </div>
                <div className="py-5 px-8 border-t border-gray-100 dark:border-slate-800">
                  <form onSubmit={submitHandler}>
                    <div className="grid grid-cols-1 gap-5">
                      <div className="">
                        <label className="form-label font-semibold">
                        WhatsApp Access Token:
                        </label>
                        <input
                          type="text"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                          placeholder=""
                          id="whatsapp_access_token"
                          name="whatsapp_access_token"
                              value={whatsappAccessToken}
                              onChange={(e) => setWhatsappAccessToken(e.target.value)}
                          required
                        />
                      </div>
                      <div className="">
                        <label className="form-label font-semibold">
                        WhatsApp API Endpoint:
                        </label>
                        <input
                          type="text"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                          placeholder=""
                          id="whatsapp_api_endpoint"
                          name="whatsapp_api_endpoint"
                          value={whatsappApiEndpoint}
                          onChange={(e) => setWhatsappApiEndpoint(e.target.value)}
                          required
                        />
                      </div>



                      <div className="">
                        <button
                          type="submit"
                          className=" py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigobg-indigo-700 text-white rounded-md"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  </>;
};

export default ConnectToWhatsapp;
