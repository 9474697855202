import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import DataLoader from "./DataLoader";
import axios from "axios";
import { toast } from "react-toastify";
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useSelector, useDispatch } from 'react-redux';
import { storeCustomerList,storeCheckedCustomerList } from "../features/selectedCustomerListSlice";
import { customerStore } from "../features/customersDataSlice";
import { useParams } from 'react-router-dom';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';

function CustomerListing() {
  const [rowSelection, setRowSelection] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [customerType, setCustomerType] = useState();
  const [customerTypeList, setCustomerTypeList] = useState();
  const [dataLoader,setDataLoader]=useState(false);
  const [storeCustomerListForCheckBox,setStoreCustomerListForCheckBox]=useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const { category } = useParams();

  const token = useSelector((state) => state.userInfo.token);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const isSidebarToggled = useSelector((state) => state.sidebarToggle.isSidebarToggled);
  const customerList = useSelector((state) => state.customerList.customerList);
  const selectedCustomerList = useSelector((state) => state.selectedCustomerList.checkedCustomerList);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const filteredCustomers = useMemo(() => {
    if (!customerList?.data) return [];
    return category ? customerList.data?.filter(customer => customer.customer_type === category) : customerList.data?customerList.data:[];
  }, [category, customerList]);


  const handleOpenModal = () => {
    setShowModal(true);
    document.body.classList.remove("overflow-hidden");
  };
  const handleCloseModal = () => {
    setShowModal(false);
    document.body.classList.remove("overflow");
  };


  const CustomerListData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await axios.post(
        // "/api/v1/customerviewset/list_users/",
        "https://suvarnakarsoftware.com/api/v1/get-customers-list",
        {
          // page:1,
          // limit:10,
          company_id:userInfo.data.company.iCompanyID,
          user_token:token
        },
        config
      );
      console.log(res.data.status)
      if (res.data&&res.data.status) {
        dispatch(customerStore(res?.data))
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    } finally {
      setDataLoader(false);
    }
  };
  

  const columns = useMemo(
    () => [
      {
        id: "name",
        header: "Name",
        accessorKey: "vName",
        size: 160,
      },
      {
        id: "email",
        header: "Email",
        accessorKey: "email",
        size: 160,
      },
      {
        id: "mobile_no",
        header: "Mobile No",
        accessorFn: (row) => row.whats_app_no?row.whats_app_no:row.vPhone, // Use accessorFn instead of accessorKey
        filterFn: 'includesString',
        Cell: ({ row }) => {
          const whatsAppNo = row.original.whats_app_no;
          const vPhone = row.original.vPhone;
          return whatsAppNo ? whatsAppNo : vPhone;
        },
        size: 160,
      },
      {
        id: "customer_type",
        header: "Customer type",
        accessorKey: "customer_type",
        size: 160,
      },
      // {
      //   id: "name",
      //   header: "Name",
      //   accessorKey: "name",
      //   size: 160,
      // },
      // {
      //   id: "email",
      //   header: "Email",
      //   accessorKey: "email",
      //   size: 160,
      // },
      // {
      //   id: "mobile_no",
      //   header: "Mobile No",
      //   accessorKey: "mobile_no",
      //   size: 160,
      // },
      // {
      //   id: "customer_type",
      //   header: "Customer type",
      //   accessorKey: "customer_type",
      //   size: 160,
      // },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: filteredCustomers,
    state: {  isLoading: dataLoader ,rowSelection },
    muiCircularProgressProps:{
      style: { color: '#4F46E5', thickness: 5, size: 55 },
    },
    muiSkeletonProps:{
      animation: 'pulse',
      height: 28,
    },
    enableColumnOrdering: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enablePinning: true,
    enableRowSelection: true,
    getRowId: (row) => row.iUserID, //give each row a more useful id
    onRowSelectionChange: setRowSelection, //connect internal row selection state to your own

    // paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    //optionally override the default column widths
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 180, //default size is usually 180
    },
    // enableColumnResizing: true,
    columnResizeMode: "onChange",
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      pagination: { pageSize: 5, pageIndex: 0 },
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [
        2,5, 10, 20, 50,
        {
          value: filteredCustomers?.length > 0 ? filteredCustomers?.length : 1,
          label: "All",
        },
      ],
      shape: "rounded",
      variant: "outlined",
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        borderRadius: "0",
        border: "none",
      },
    },

    renderTopToolbar: ({ table }) => {
      
      const handleSelectTemplate = () => {

        const selectedRows = Object.keys(rowSelection).map((key) => {
          const id = parseInt(key);
          const foundData = customerList.data.find((item) => item.iUserID === id);
          return foundData ? {"name":foundData.vName,"phone_number":foundData.whats_app_no?foundData.whats_app_no:foundData.vPhone} : null;
        });
        const storeCustomerListForCheckBox = Object.keys(rowSelection).map((key) => {
          const id = parseInt(key);
          const foundData = customerList.data.find((item) => item.iUserID === id);
          return foundData ;
        });

        if (selectedRows.length==0) {
          toast.warning("Not Selected Any Customers.");
        } else {
          dispatch(storeCustomerList(selectedRows))
          dispatch(storeCheckedCustomerList(storeCustomerListForCheckBox))
          navigate("/marketing-templates");
        }
      };

      const handleUpdateCategory=()=>{
        handleOpenModal()
      }

      if (table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) {
        return (
          <div className=" flex gap-2 p-2 justify-between">
          <div className="flex gap-2 items-center">
              <MRT_GlobalFilterTextField table={table} />
              <MRT_ToggleFiltersButton table={table} />
          </div>
          <div className="flex gap-2">
            <button
              className="bg-blue-600 text-white rounded px-4 py-2"
              onClick={handleSelectTemplate}
            >
              Select Template
            </button>
           
            <button
              className="bg-blue-600 text-white rounded px-4 py-2"
              onClick={handleUpdateCategory}
            >
              Update Category
            </button>
          </div>
        </div>
        );
      }
      return null;
    },
  });

  const customerTypeData= async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await axios.post(
        "https://suvarnakarsoftware.com/api/v1/get-category-list",
        // "http://127.0.0.1:8000/api/v1/categoriesviewset/list_category/1/",
        {company_id:userInfo.data.company.iCompanyID,
        user_token:token},
        config
      );

      if (res.data && res.data.status) {
        const categories = JSON.parse(res.data.data[0].category)
        setCustomerTypeList(categories);
      } else {
        setCustomerTypeList([]);
      }

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const selectedRows = Object.keys(rowSelection).map((key) => {
      const id = parseInt(key);
      const foundData = customerList.data.find((item) => item.iUserID === id);
      return foundData ?foundData.iUserID : null;
    }).filter(id => id !== null);

    
    handleCloseModal();
    
    try {
      // Update category
      await updateCategory({
        customer_id: selectedRows,
        customer_type: customerType,
        company_id: userInfo.data.company.iCompanyID,
        user_token: token
      });

      setRowSelection({});
      setCustomerType();
      // Fetch the updated customer list
      setDataLoader(true);
      await CustomerListData();

      // Clear row selection
    } catch (error) {
      console.error('Error updating category:', error);
      // Optionally handle the error here, e.g., show a notification
    } 

  }


  const updateCategory = async (userCategory)=>{
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const res = await axios.post(
        // "http://127.0.0.1:8000/api/v1/categoriesviewset/update_user_category/",
        "https://suvarnakarsoftware.com/api/v1/update-customer-category",
        userCategory,
        config
      );

      toast.success(res.data.message)

    } catch (error) {
      if (error.response) {
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      } else if (error.request) {
        console.error("Request Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  }


  useEffect(() => {
    if(!token){
      navigate('/error')
      return;
    }
    const fetchData = async () => {
      try {
        // setDataLoader(true);
        // console.log("customer",customerList.data.length)
        if(!customerList.data){
          setDataLoader(true)
          await CustomerListData();
          
        }
        // setDataLoader(false);
        if(!customerTypeList){
          await customerTypeData();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setDataLoader(false);
      }
    };
    fetchData();

    if (category) {
      table.setColumnFilters([{ id: 'customer_type', value: category }]);
    } else {
      table.setColumnFilters([]);
    }

    if (selectedCustomerList.length) {
      const newRowSelection = {};
      selectedCustomerList.forEach(customer => {
        newRowSelection[customer.iUserID] = true;
      });
      setRowSelection(newRowSelection);
    }


    return () => {
      table.setColumnFilters([]); 
    };

  }, [category, table,showModal,token,navigate,selectedCustomerList]);  
 

  
  return (
    <>
      <div className={`page-wrapper ${isSidebarToggled ? 'toggled' : ''}`}>
        <SideBar />

        <main className="page-content bg-gray-50 dark:bg-slate-800">
        <Header toggleSidebar={handleToggleSidebar}/>
          <div className="container-fluid relative px-3">
            <div className="layout-specing">
              <div className="p-6 rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                {customerList ? <MaterialReactTable table={table} /> : []}
              </div>
              {showModal && (
                      <div className="modal">
                        <div className="modal-content">
                          <span
                            className="close -mt-5"
                            onClick={handleCloseModal}
                          >
                            &times;
                          </span>
                          <div className="section-title ">
                            <h4 className="text-1xl font-semibold uppercase mb-3">
                            </h4>
                              <div className="w-full p-6">
                                <form onSubmit={handleFormSubmit} className="flex flex-col h-full justify-between">
                                  <div className="mb-3">
                                    <label className="font-semibold">Customer Type:</label>
                                    <select
                                      name="customer_type"
                                      className="form-select form-input mt-4 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                      value={customerType}
                                      required
                                      onChange={(e) => setCustomerType(e.target.value)}
                                    >
                                      <option value="" selected>
                                        Select...
                                      </option>
                                      {customerTypeList && customerTypeList.map((input, index) => (
                                        <option key={index} value={input}>{input}</option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="flex justify-end">
                                    <button
                                      type="submit"
                                      className="py-2 px-5 mt-4 inline-block font-semibold tracking-wide border duration-500 text-base text-center text-white rounded-md bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700"
                                    >
                                      Update Category
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
}

export default CustomerListing;
